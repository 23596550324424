import React, { useState, useEffect, useCallback } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAuth } from "../../../../context/auth-context";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Form, Input, Popconfirm, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import AddAllocation from "../add-allocation";
import AddUser from "../add-user";
import RemoveUser from "../remove-user";
const moment = require("moment-timezone");

const UploadAllocations = () => {
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  let [added, setAdded] = useState([]);
  const [userModal, setUserModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [userVal, setUserVal] = useState("");
  const [userValues, setState] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const [form] = Form.useForm();
  const { projectId, departmentId } = useParams();
  const history = useHistory();

  const fetchUsers = useCallback(
    (query) => {
      run(client(`allocation/list/${projectId}/${departmentId}`));
      setUsersLoading(true);
    },
    [run]
  );

  useEffect(() => {
    if (data?.message === "Fetched Allocations") {
      setUsers(transformTableData(data.users));
      setState(data.addedUsers);
      setUsersLoading(false);
    }
    if (data?.message === "Deleted Allocation Records") {
      message.success(data.message);
    }
  }, [data, modal, remove]);

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const removeClick = (val) => {
    setRemoveModal(true);
    setUserVal(val);
  };

  const deleteRecord = async (val) => {
    const user = val.current_user;

    await run(
      client(`allocation/delete/${val?.project_name}/${val.account_id}`, {
        method: "PUT",
        body: { user },
      })
    );
    history.push(`/projectAllocation/${projectId}`);
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      width: 200,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Department Name",
      dataIndex: "subject_name",
      key: "subject_name",
      width: 200,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Allocation ID",
      dataIndex: "account_id",
      key: "account_id",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Current User",
      dataIndex: "current_user",
      key: "current_user",
      width: 200,
    },
    {
      title: "Current User Allocation Time",
      dataIndex: "current_user_allocation_time",
      key: "current_user_allocation_time",
      width: 200,
      render: (rec) => (
        <>
          {
            // rec?(rec).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}).split("GMT")[0]:""
            rec
              ? moment(rec).tz("Asia/Kolkata").format("DD/MM/YYYY hh:mm:ss a")
              : ""
          }
        </>
      ),
    },
    {
      title: "Action",
      key: "x",
      dataIndex: "",
      width: 500,
      render: (record) => (
        <>
          <Link to={`/allocation/${record.project_name}/${record.account_id}`}>
            <Button type="link">View summary</Button>
          </Link>

          <Button
            type="link"
            disabled={record.current_user !== "" ? true : false}
            onClick={() => {
              setUserModal(true);
              setAccountId(record.account_id);
              setAdded(userValues);
            }}
          >
            Add User
          </Button>
          <Button
            type="link"
            disabled={record.current_user === "" ? true : false}
            onClick={() => removeClick(record.current_user)}
          >
            Remove User
          </Button>
          <Popconfirm
            title="Sure to remove?"
            onConfirm={() => {
              deleteRecord(record);
            }}
          >
            <Button type="danger">Delete Record</Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const transformTableData = (data) =>
    data.map((user, index) => {
      let { ...rest } = user;

      return {
        key: index,
        ...rest,
        sr: index + 1,
      };
    });

  useEffect(() => {
    fetchUsers();
  }, [modal, userModal, removeModal]);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Add Allocations</h1>
        {/* <div>
          <Button
            type="ghost"
            onClick={showModal}
            style={{ marginRight: "1rem" }}
          >
            Add Allocations
          </Button>

        </div> */}
      </div>
      <Table
        loading={usersLoading}
        data={users}
        columns={columns}
        size="middle"
      />

      <AddUser
        modal={userModal}
        setModal={setUserModal}
        accountId={accountId}
        added={added}
      />
      <RemoveUser
        modal={removeModal}
        setModal={setRemoveModal}
        currentUser={userVal}
      />
    </div>
  );
};

export default UploadAllocations;
