import locale from 'antd/es/date-picker/locale/hi_IN';
import React, { useEffect, useState } from "react";
import { Modal, Form, message, Button, DatePicker, Checkbox } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";
import moment from "moment";
import { getToken } from "../../../../utils/auth-client";
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const RemoveUser = ({
  modal,
  setModal,
  currentUser
}) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [revokeTime, setRevokeTime] = useState('');
  const { projectId, departmentId } = useParams();
  const [userForm] = Form.useForm();

  const removeUser = async (values) => {
    await run(client(`timesheet/${projectId}/${currentUser}/remove`, { body: values, method: "PATCH" })).then((res) => {
      setModal(false);
      userForm.resetFields();
      if(res.message==="Revoke time must be greater than allocation time"){
        message.error(res.message)
      }
      else {
        message.success(res.message);
      }
    }
    )
  };
  const handleChangeWEF = (e) => {
    setRevokeTime(e.target.value)
  };
  const onOk = (value) => {
    console.log("onOk: ", moment().format());
    console.log("onOk: ", typeof value);
    console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
  };

  const disabledDate = (current)=>{
    return current && current > dayjs().endOf('day').endOf('hours');
  }

  return (
    <Modal
      title="Remove User"
      visible={modal}
      onCancel={() => {
        setModal(false)
        userForm.resetFields();
      }}
    >
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={removeUser}
      >

        {/* <Form.Item
          label="Add Effective Date">
          <Checkbox onChange={(e) => setChecked(e.target.checked)}></Checkbox>
        </Form.Item>
        {
          checked === true && */}
          <Form.Item
            label="Date Effective :"
            onChange={handleChangeWEF}
            name='revokeTime'
            rules={[
              {
                required: true,
                message: "Please input the date effective",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={true} onOk={onOk} style={{ width: '100%' }} 
            disabledDate={disabledDate}
            />
          </Form.Item>
        {/* } */}

        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="danger" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default RemoveUser;
